<template>
  <div v-if="isAuthorized">
      <slot>No secured content found</slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    allowedRoles: {
      type: [String, Array],
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser", "userRoles"]),
    isAuthorized() {
      let authorized = false;
      if (!this.allowedRoles || this.allowedRoles.length < 1) {
        authorized = false;
      }

      if (this.userRoles && this.userRoles.length > 0) {
        authorized = this.userRoles.some((r) => this.allowedRoles.includes(r));
      } else {
        authorized = false;
      }

      return authorized;
    },
  },
};
</script>

<style></style>
